


























































import {defineComponent, onMounted, ref, useContext,} from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import {CacheTagPrefix, useCache} from '@vue-storefront/cache';
import {SfBanner, SfBannerGrid, SfHero} from '@storefront-ui/vue';

export default defineComponent({
  name       : 'HomePage',
  components : {
    SfHero,
    LazyHydrate,
    SfBanner,
    SfBannerGrid,
    CallToAction      : () => import(/* webpackPrefetch: true */ '~/components/CallToAction.vue'),
    InstagramFeed     : () => import(/* webpackPrefetch: true */ '~/components/InstagramFeed.vue'),
    MobileStoreBanner : () => import(/* webpackPrefetch: true */ '~/components/MobileStoreBanner.vue'),
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup()
  {
    const {addTags} = useCache();
    const {app} = useContext();
    const year = new Date().getFullYear();
    const {isDesktop} = app.$device;
    const heroes = ref([
      {
title: 'Colorful summer dresses are already in store',
        subtitle: 'SUMMER COLLECTION 2021',
        buttonText: 'Learn more',
        background: '#eceff1',
        image: {
          mobile:
            '/Bilder/123.png',
          desktop:
            '/Bilder/123.png',
        },
        link: '/c/women/women-clothing-shirts',
      },
{
        title: 'Colorful summer dresses are already in store',
        subtitle: 'SUMMER COLLECTION 2021',
        buttonText: 'Learn more',
        background: '#eceff1',
        image: {
          mobile:
            '/Bilder/1.png',
          desktop:
            '/Bilder/1.png',
        },
        link: '/c/women/women-clothing-shirts',
      },
      {
        title: 'Colorful summer dresses are already in store',
        subtitle: 'SUMMER COLLECTION 2021',
        buttonText: 'Learn more',
        background: '#fce4ec',
        image: {
          mobile:
            '/Bilder/2.PNG',
          desktop:
            '/Bilder/2.PNG',
        },
        link: '/c/women/women-clothing-dresses',
      },
      {
        title: 'Colorful summer dresses are already in store',
        subtitle: 'SUMMER COLLECTION 2021',
        buttonText: 'Learn more',
        background: '#efebe9',
        image: {
          mobile:
            '/Bilder/3.PNG',
          desktop:
            '/Bilder/3.PNG',
        },
        link: '/c/women/women-shoes-sandals',
        className:
          'sf-hero-item--position-bg-top-left sf-hero-item--align-right',
      }
    ]);
    const banners = ref([
      {
        slot        : 'banner-A',
        subtitle    : 'Dresses',
        title       : null,
        description : null,
        buttonText  : null,
        image       : {
          mobile  :
            '/Bilder/Lower_left_banner.png',
          desktop :
            '/Bilder/Lower_left_banner.png',
        },
        class       : 'sf-banner--slim desktop-only',
        link        : '/c/women/women-clothing-skirts',
      },
      {
        slot        : 'banner-B',
        subtitle    : null,
        title       : null,
        description : null,
        buttonText  : null,
        image       : {
          mobile  :
            '/Bilder/Lower_middle_banner.png',
          desktop :
            '/Bilder/Lower_middle_banner.png',
        },
        class       : 'sf-banner--slim banner-central desktop-only',
        link        : '/c/women/women-clothing-dresses',
      },
      {
        slot     : 'banner-C',
        subtitle : null,
        title    : null,
        image    : {
          mobile  :
            '/Bilder/Lower_right_upper_banner.png',
          desktop :
            '/Bilder/Lower_right_upper_banner.png',
        },
        class    : 'sf-banner--slim banner__tshirt',
        link     : '/c/women/women-clothing-shirts',
      },
      {
        slot     : 'banner-D',
        subtitle : null,
        title    : null,
        image    : {
          mobile  :
            '/Bilder/Lower_right_lower_banner.png',
          desktop :
            '/Bilder/Lower_right_lower_banner.png',
        },
        class    : 'sf-banner--slim',
        link     : '/c/women/women-shoes-sandals',
      },
    ]);
    const callToAction = ref({
      title       : app.i18n.t('Subscribe to Newsletters'),
      description : app.i18n.t('Be aware of upcoming sales and events. Receive gifts and special offers!'),
      buttonText  : app.i18n.t('Subscribe'),
      imageSrc    : '/homepage/newsletter.webp',
      imageWidth  : isDesktop ? 1240 : 400,
      imageHeight : isDesktop ? 202 : 200,
      imageConfig : {
        fit    : 'cover',
        format : 'webp',
      },
    });

    onMounted(() => {
      addTags([{prefix : CacheTagPrefix.View, value : 'home'}]);
    });

    // @ts-ignore
    return {
      banners,
      callToAction,
      heroes,
    };
  },
});
